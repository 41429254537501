/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-03-17",
    versionChannel: "nightly",
    buildDate: "2024-03-17T00:07:02.167Z",
    commitHash: "5d6d097fb1989ac381653270ad8127e7d2f4d4db",
};
